import AxiosServices from 'helpers/AxiosServices'
import serializeForm from 'helpers/SerializeForm'
import {
    checkExpiresToken,
    generateRefreshToken,
    getRefreshToken,
    getSocialMediaToken,
    getToken,
    removeToken,
    setCookies
} from 'helpers/StorageServices'
import {getTokenFirebase, onMessageListener} from 'helpers/firebase'
import moment from "moment"
import { get_payments } from 'actions/paket'
import { get_verificationData } from 'actions/profile'

const checkIfMinus = (value) => {
    const regex = new RegExp('-')
    return regex.test(value)
}

const convertToRupiah = (number) => {
    let rupiah = ''		
    let numberrev = number.toString().split('').reverse().join('')
    for(var i = 0; i < numberrev.length; i++) if(i%3 === 0) rupiah += numberrev.substr(i,3)+'.'
    return rupiah.split('',rupiah.length-1).reverse().join('')
}

const setLoginCookies = (authData) => {
    const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
    const expiresDate = moment(currentDate).add(authData.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss')

    setCookies().set('_authToken', authData.access_token, {
        path: '/',
        sameSite: 'Lax'
    })

    setCookies().set('_authRefreshToken', authData.refresh_token, {
        path: '/',
        sameSite: 'Lax'
    })

    setCookies().set('_authExpiresToken', expiresDate, {
        path: '/',
        sameSite: 'Lax'
    })
}

const setLoginSocialMediaToken = (token) => {
    setCookies().set('_tknSocialMedia', token, {
        path: '/',
        sameSite: 'Lax'
    })
}

const isLastPaymentSuccess = async () => {
    const payment = await get_payments();
    const data = payment.data.results;
    if (data.length > 0) {
        const lastPayment = data[0];
        if (lastPayment.status == "success") {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }  
}

const isLastPaymentPending = async () => {
    const payment = await get_payments();
    const data = payment.data.results;
    if (data.length > 0) {
        const lastPayment = data[0];
        if (lastPayment.status == "pending") {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }  
}

const isLastPaymentVerified = async () => {
    const payment = await get_payments()
    const data = payment.data.results;
    if (data.length > 0) {
        const lastPayment = data[0];
        if (lastPayment.status == "success" && lastPayment.expired_at != null) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const isLastPaymentExpired = async () => {
    const payment = await get_payments()
    const data = payment.data.results;
    if (data.length > 0) {
        const lastPayment = data[0];
        if (lastPayment.status == "success" && lastPayment.expired_at != null) {
            console.log('is expired', moment(lastPayment.expired_at).isBefore(moment()));
            if (moment(lastPayment.expired_at).isBefore(moment())) {
                console.log('moment expired');
                return true;
            } else {
                console.log('moment is not expired');
                return false;
            }
        } else {
            return true;
        }
    } else {
        return true;
    }
}

const isVerificationDataIncludeNull = async () => {
    const payment = await get_payments()
    const data = payment.data.results;
    if (data.length > 0) {
        const isOnePending = data.map(item => item.status);
        if (isOnePending.includes('success')) {
            const resp = await get_verificationData()
            const values = resp.data;
            if (values.nik == null || values.full_name == null || values.telephone == null || values.address == null || values.postal_code == null || values.ktp == null) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        console.log("length data is zero");
        return false;
    }
}

const isVerificationApproved = async () => {
    const payment = await get_payments()
    const data = payment.data.results;
    if (data.length > 0) {
        const isOnePending = data.map(item => item.status);
        if (isOnePending.includes('success')) {
            const resp = await get_verificationData()
            const values = resp.data;
            return values.tax_status;
        }
    } else {
        console.log("length data is zero");
        return false;
    }
}

export {
    AxiosServices,
    checkExpiresToken,
    checkIfMinus,
    convertToRupiah,
    getToken,
    getTokenFirebase,
    getRefreshToken,
    generateRefreshToken,
    getSocialMediaToken,
    removeToken,
    setLoginCookies,
    setLoginSocialMediaToken,
    serializeForm,
    setCookies,
    onMessageListener,
    isLastPaymentSuccess,
    isLastPaymentPending,
    isLastPaymentVerified,
    isLastPaymentExpired,
    isVerificationDataIncludeNull,
    isVerificationApproved
}