import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NoInternetLogo } from 'assets/images/index'

class NoInternet extends React.Component {

    render() {
        const { props } = this

        return (
            <section className={`noInternet justify-content-center align-items-center py-3 ${props.show ? 'd-none' : 'd-flex'}`}>

                <Row className="text-center">
                    <Col xs={12} md={6}>
                        <img 
                            src={NoInternetLogo}
                            alt="No Internet" />

                        <p>Maaf, sambungan pada internet anda sedang bermasalah, Silahkan periksa kembali.</p>
                    </Col>
                </Row>

            </section>
        )
    }
}

export default NoInternet