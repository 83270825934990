import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig)

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

export const getTokenFirebase = () => {
    if (messaging !== null) {
        return messaging.getToken({vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY}).then((currentToken) => {
            if (currentToken) {
                return currentToken
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        })
    } else {
        console.warn('Your browser not support firebase messaging')
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload)
        })
    })
