import React from 'react'

const MainContext = React.createContext({
    title: '',
    profile: null,
    breadcrumb: [],
    isLogged: false,
    stockTicker: null,
    isNotifLoading: true,
    notifications: null,
    authProvider: null,
    isPageShow: false,
    setTitle: () => {},
    setProfile: () => {},
    setBreadcrumb: () => {},
    setLogged: () => {},
    setStockTicker: () => {},
    setIsNotifLoading: () => {},
    setNotifications: () => {},
    setAuthProvider: () => {},
    setPageShow: () => {},
})

class MainProvider extends React.Component {
    state = { 
        title: 'Ilmu Saham',
        profile: null,
        breadcrumb: [],
        isLogged: false,
        stockTicker: null,
        isNotifLoading: true,
        notifications: null,
        authProvider: null,
        isPageShow: false,
        isRegistered: false,
    }

    componentDidMount() {
        this.set_stockTicker(JSON.parse(localStorage.getItem('stockTicker')) ?? null)
        this.set_notification(JSON.parse(localStorage.getItem('notifications')) ?? null)
        this.set_authProvider(localStorage.getItem('authProvider') ?? null)
        this.set_pageShow(localStorage.getItem('isPageShow') ?? null)
    }

    set_title = (nextValue) => {
        this.setState({
            title: nextValue
        })
    }

    set_profile = (nextValue) => {
        this.setState({
            profile: nextValue
        })
    }

    set_breadcrumb = (nextValue) => {
        this.setState({
            breadcrumb: nextValue
        })
    }

    set_logged = (nextValue) => {
        this.setState({
            isLogged: nextValue
        })
    }

    set_stockTicker = (nextValue) => {
        this.setState({
            stockTicker: nextValue
        }, () => {
            localStorage.setItem('stockTicker', JSON.stringify(nextValue))
        })
    }

    set_isnNotifLoading = (nextValue) => {
        this.setState({
            isNotifLoading: nextValue
        })
    }

    set_notification = (nextValue) => {
        this.setState({
            notifications: nextValue
        }, () => {
            localStorage.setItem('notifications', JSON.stringify(nextValue))
        })
    }

    set_authProvider = (nextValue) => {
        this.setState({
            authProvider: nextValue
        }, () => {
            localStorage.setItem('authProvider', nextValue)
        })
    }

    set_pageShow = (nextValue) => {
        this.setState({
            isPageShow: nextValue
        }, () => {
            localStorage.setItem('isPageShow', nextValue)
        })
    }

    set_register = (nextValue) => {
        this.setState({
            isRegistered: nextValue
        })
    }

    render() {
        const { props, state } = this

        return (
            <MainContext.Provider
                value={{
                    title: state.title,
                    profile: state.profile,
                    breadcrumb: state.breadcrumb,
                    isLogged: state.isLogged,
                    stockTicker: state.stockTicker,
                    isNotifLoading: state.isNotifLoading,
                    notifications: state.notifications,
                    authProvider: state.authProvider,
                    isPageShow: state.isPageShow,
                    isRegistered: state.isRegistered,
                    setTitle: this.set_title,
                    setProfile: this.set_profile,
                    setBreadcrumb: this.set_breadcrumb,
                    setLogged: this.set_logged,
                    setStockTicker: this.set_stockTicker,
                    setIsNotifLoading: this.set_isnNotifLoading,
                    setNotifications: this.set_notification,
                    setAuthProvider: this.set_authProvider,
                    setPageShow: this.set_pageShow,
                    setIsRegistered: this.set_register,
                }}>
                    { props.children }
            </MainContext.Provider>
        )
    }
}

export default MainContext

export {
    MainProvider
}