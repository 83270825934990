const WEB = {
    SAHAM_TODAY             : '/saham-today',
    HOMEPAGE                : '/',
    PAKETPAGE               : '/paket',
    PAKETPAGE_PAYMENT       : '/paket/pembayaran/:slug',
    
    ILMU_SAHAM              : '/ilmu-saham',
    ILMU_SAHAM_DETAIL       : '/ilmu-saham/:slug/detail',

    VVIP_CLASS                  : '/vvip-class',
    VVIP_CLASS_VIDEO            : '/vvip-class/video',
    VVIP_CLASS_VIDEO_DETAIL     : '/vvip-class/video/:slug/detail',
    VVIP_SPECIAL                : '/vvip-special',
    VVIP_SPECIAL_VIDEO_DETAIL   : '/vvip-special/:slug/detail',

    AUTH_LOGIN              : '/auth/login',
    AUTH_REGISTER           : '/auth/register',
    AUTH_FORGOT_PASSWORD    : '/auth/forgot-password',
    AUTH_CHANGE_PASSWORD    : '/auth/change-password',

    PROFILE                     : '/profile',
    PROFILE_FORM                : '/profile/form',
    PROFILE_RIWAYAT_PEMBAYARAN  : '/profile/riwayat-pembayaran',

    VERIFICATION                : '/verification',
    
    NO_INTERNET             : '/no-internet',

    PRIVACY_POLICY          : '/privacy-policy',
    TERMS_CONDITIONS        : '/terms-conditions',
}

const API = {
    LOGIN               : '/o/token/',
    LOGIN_SOCIAL_MEDIA  : '/o/convert-token',
    REGISTER            : '/api/register',
    FORGOT_PASSWORD     : '/api/forgot-password',
    LOGOUT              : '/o/revoke_token/',

    COURSE_LIST             : '/api/courses',
    COURSE_START            : '/api/courses/start',
    COURSE_MATERIAL         : '/api/courses/materials',
    COURSE_MATERIAL_DETAIL  : '/api/materials/:slug',

    FCM_DEVICES             : '/api/devices/',

    SAHAM_TODAY             : '/api/timelines',
    SAHAM_TODAY_VVIP        : '/api/timelines?type=vvip',
    ILMU_SAHAM              : '/api/ilmu-saham',
    ILMU_SAHAM_TERBARU      : '/api/ilmu-saham/terbaru',
    ILMU_SAHAM_TRENDING     : '/api/ilmu-saham/trending',
    ILMU_SAHAM_DETAIL       : '/api/media/:slug',

    PROFILE                 : '/api/profile',
    PROFILE_CHANGE_PASSWORD : '/api/change-password',
    PROFILE_DELETE_ACCOUNT  : '/api/remove-account',

    STOCK_IHSG              : '/api/stocks/ihsg',
    // STOCK_TOP_NILAI         : '/api/stocks/top-nilai',
    // STOCK_TOP_FREKUENSI     : '/api/stocks/top-frekuensi',
    STOCK_TOP_NILAI         : '/api/stocks/saham-paling-profit',
    STOCK_TOP_FREKUENSI     : '/api/stocks/saham-paling-ramai',

    PACKAGES                    : '/api/packages',

    PAYMENTS                    : '/api/payments',

    VVIP_SPECIAL                : '/api/vvip-special',
    VVIP_SPECIAL_TRENDING       : '/api/vvip-special/trending',
    VVIP_SPECIAL_TERBARU        : '/api/vvip-special/terbaru',

    NOTIFICATION                : '/api/notifications',
    NOTIFICATION_MARK_AS_READ   : 'api/notifications/mark-as-read/:slug',

    VERIFICATION                : '/api/taxes/verification'
}

export {
    WEB,
    API,
}