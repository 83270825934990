import moment from 'moment'
import {WEB} from '../constants'
import Cookies from 'universal-cookie'
import {serializeForm} from 'helpers/index'
import {post_loginUser} from '../actions/auth'
import {initFormRefreshToken} from 'pages/auth/common/const'

export const setCookies = () => {
    return new Cookies()
}

export const getToken = () => {
    return setCookies().get('_authToken') || null;
}

export const getRefreshToken = () => {
    return setCookies().get('_authRefreshToken') || null;
}

export const getExpiresToken = () => {
    return setCookies().get('_authExpiresToken') || null;
}

export const getSocialMediaToken = () => {
    return setCookies().get('_tknSocialMedia') || null;
}

export const removeToken = () => {
    setCookies().remove('_authToken', {
        path: '/',
        sameSite: 'Lax'
    })

    setCookies().remove('_authRefreshToken', {
        path: '/',
        sameSite: 'Lax'
    })

    setCookies().remove('_authExpiresToken', {
        path: '/',
        sameSite: 'Lax'
    })

    setCookies().remove('_tknSocialMedia', {
        path: '/',
        sameSite: 'Lax'
    })

    localStorage.setItem('stockTicker', null)
    localStorage.setItem('notifications', null)
}

export const generateRefreshToken = async () => {
    const token = await getToken()
    const refreshToken = await getRefreshToken()
    const formValues = {...initFormRefreshToken}
    formValues.client_id = process.env.REACT_APP_CLIENT_ID
    formValues.client_secret = process.env.REACT_APP_CLIENT_SECRET
    formValues.refresh_token = refreshToken
    const formData = serializeForm(initFormRefreshToken, formValues, 'form-data')
    const isExpiredToken = await checkExpiresToken()

    if (token && refreshToken) {
        if (isExpiredToken) {
            const login = await post_loginUser(formData)
            if (login.status === 200) {
                const { data } = login
                return data
            } else {
                if (login.status === 401) {
                    removeToken()
                    window.location.replace(WEB.AUTH_LOGIN)
                }
            }
        }
    }
}

export const checkExpiresToken = async () => {
    const expiresDate = await getExpiresToken()
    const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')

    return moment(expiresDate).isSameOrBefore(currentDate)
}
