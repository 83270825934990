import React from 'react'
import SocialLogin from 'react-social-login'
import Button from 'react-bootstrap/Button'

class SocialLoginCustom extends React.Component {

    render() {
        const { children, triggerLogin, triggerLogout, ...props } = this.props

        return (
            <Button onClick={triggerLogin} {...props}>
                {children}
            </Button>
        );
    }
}

export default SocialLogin(SocialLoginCustom);