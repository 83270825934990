import React from 'react'
import Spinner from "react-bootstrap/Spinner"

class DimmerCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        return (
            <div className="dimmer">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        )
    }
}

export default DimmerCustom