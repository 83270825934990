import React from 'react'
import PropTypes from 'prop-types'

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        const { props } = this

        return (
            <div 
                className="modal" 
                style={{ display: props.isModalOpen && 'block' }}>

                <div className="modal-content">
                    <div className="modal-header">
                        <span 
                            className="close" 
                            onClick={() => props.onShow(false, null)}>
                                &times;
                        </span>
                        <h2>{ props.modalTitle }</h2>
                    </div>
                    <div className="modal-body">
                        {props.modalBody}
                    </div>
                </div>

            </div>
        )
    }
}

Modal.propTypes = {
    isModalOpen: PropTypes.bool,
    modalTitle: PropTypes.string,
    modalBody: PropTypes.any,
    onShow: PropTypes.func,
}

export default Modal