import { AxiosServices } from 'helpers/index'
import { API } from 'constants/index'

const get_stockIhsg = () => AxiosServices().get(API.STOCK_IHSG)
const get_listStockTopNilai = () => AxiosServices().get(API.STOCK_TOP_NILAI)
const get_listStockTopFrekuensi = () => AxiosServices().get(API.STOCK_TOP_FREKUENSI)
const get_listNotifications = () => AxiosServices().get(API.NOTIFICATION)
const get_notificationsMarkAsRead = (itemId) => AxiosServices().get(API.NOTIFICATION_MARK_AS_READ.replace(':slug', itemId))

export {
    get_stockIhsg,
    get_listStockTopNilai,
    get_listStockTopFrekuensi,
    get_listNotifications,
    get_notificationsMarkAsRead,
}