import React from 'react'
import PropTypes from 'prop-types'
import { CardCourseCustom } from 'components/index'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'

export const Menu = (collection) =>
    collection.data.map((item, key) => {
        return (
            <CardCourseCustom
                key={key}
                thumbnail={item.thumbnail}
                link={{
                    pathname: collection.link.replace(':slug', item.id),
                }}
                title={item.title}
            />
        )
    });


const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};


const ArrowLeft = Arrow({ text: <FontAwesomeIcon icon={faChevronLeft} />, className: 'arrow-prev' });
const ArrowRight = Arrow({ text: <FontAwesomeIcon icon={faChevronRight} />, className: 'arrow-next' });

class ScrollHorizontalCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selected: 0,
            collections: [],
            alignCenter: false,
            clickWhenDrag: false,
            dragging: true,
            hideArrows: true,
            hideSingleArrow: true,
            translate: 0,
            transition: 0.3,
            wheel: true,
        }
    }

    onLastItemVisible = () => {
        const { loadMore } = this.props
        const { hasMore } = this.props.collections

        if (hasMore) {
            loadMore()
        }
    }

    onUpdate = ({ translate }) => {
        this.setState({ translate });
    }

    render() {
        const { props, state } = this

        return(
            <>
                <ScrollMenu
                    alignCenter={state.alignCenter}
                    data={Menu(props.collections)}
                    onLastItemVisible={this.onLastItemVisible}
                    hideArrows={state.hideArrows}
                    hideSingleArrow={state.hideSingleArrow}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={state.selected}
                    translate={state.translate}
                    onUpdate={this.onUpdate}
                />
            </>
        )
    }

}

ScrollHorizontalCard.defaultProps = {
    collections: null,
}

ScrollHorizontalCard.propTypes = {
    collections: PropTypes.object,
    loadMore: PropTypes.func,
}

export default ScrollHorizontalCard