import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import { NavLink as RouterLink } from "react-router-dom";
import { PlayOrange, PadlockLogo } from "assets/images/index";


class CardCourseCustom extends React.Component {

    render() {
        const { props } = this

        return (
            <Card
                className={`card-item ${props.isLocked ? 'locked' : ''}`}
                style={{
                    backgroundImage: `linear-gradient(to bottom, ${props.isLocked ? 'rgba(0, 0, 0, .8)' : 'transparent'}, #000000), url(${props.thumbnail})`,
                    backgroundPosition: "top center"
                }}>

                {
                    !props.isLocked ?
                        <div style={{margin: 'auto'}}>
                            <RouterLink to={props.link} disabled >
                                {/* <Card.Body>
                                    <Card.Img style={{height: '100px', width: '100px'}} variant="top" src={PlayOrange} alt="Play" />
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Title>{props.title}</Card.Title>
                                </Card.Footer> */}
                                <img src={PlayOrange} style={{margin: 'auto'}} />
                            </RouterLink>
                        </div>
                        :
                        <>
                            <Card.Body>
                                <Card.Img variant="top" src={PadlockLogo} alt="Lock" />
                            </Card.Body>
                            <Card.Footer>
                                <Card.Title>{props.title}</Card.Title>
                            </Card.Footer>
                        </>
                }

            </Card>
        )
    }
}

CardCourseCustom.defaultProps = {
    thumbnail: null,
    link: null,
    title: null,
    isLocked: false,
}

CardCourseCustom.propTypes = {
    thumbnail: PropTypes.string,
    link: PropTypes.any,
    title: PropTypes.string,
    isLocked: PropTypes.bool
}

export default CardCourseCustom