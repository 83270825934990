import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from 'helpers/index'
import { WEB, MainContext } from 'constants/index'
import { DimmerCustom } from 'components/index'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const context = useContext(MainContext)

    return (
        <Route
        {...rest}
            render={(props) => getToken() ?
                    (context.isPageShow || context.isLogged) ?
                        <Component {...props} />
                    :
                        <DimmerCustom />
                :
                 <Redirect to={{ 
                     pathname: WEB.AUTH_LOGIN, 
                     state: { 
                         from: props.location 
                        } 
                    }} 
                />}
        />
    )
}
  
export default PrivateRoute
