import axios from 'axios'
import { 
    getToken,
    generateRefreshToken,
    checkExpiresToken,
    removeToken } from 'helpers/index'
import { WEB } from '../constants'
import moment from 'moment'
import { setCookies } from 'helpers/index'

const AxiosServices = (withBearier = true) => {
    const configAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 50000,
    })

    configAxios.interceptors.request.use(
        async (request) => {
            const token = await getToken()
            const isExpiredToken = await checkExpiresToken()
            if (withBearier) {
                if (isExpiredToken) {
                    const generateToken = await generateRefreshToken()
                    if (generateToken) {
                        const currentDate = moment().format('YYYY-MM-DD HH:mm:ss')
                        const expiresDate = moment(currentDate).add(generateToken.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss')

                        setCookies().set('_authToken', generateToken.access_token, {
                            path: '/',
                            sameSite: 'Lax'
                        })

                        setCookies().set('_authRefreshToken', generateToken.refresh_token, {
                            path: '/',
                            sameSite: 'Lax'
                        })

                        setCookies().set('_authExpiresToken', expiresDate, {
                            path: '/',
                            sameSite: 'Lax'
                        })
                    request.headers.common['Authorization'] = `Bearer ${generateToken.access_token}`
                    }
                } else {
                    request.headers.common['Authorization'] = `Bearer ${token}`
                    request.headers.common['Content-Type'] = 'application/json'
                }
            }

            return request
        },
        (error) => {
            return Promise.reject(error)
        }
    )
    
    configAxios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            const { response } = error
    
            if (response) {
                if (response.status === 403 || response.status === 419) {
                    // window.location.href = '/auth/login'
                    return response
                }
                if (response.status === 401) {
                    try {
                        removeToken()
                    } catch (err) {
                        console.warn(`Remove token is failed ${err}`)
                    }
                    
                    window.location.replace(WEB.AUTH_LOGIN)
                    Promise.reject(error)
                }
                if (response.status === 400 || response.status === 422 || response.status === 429) {
                    return response
                }
                if (response.status === 503 || response.status === 502) {
                    Promise.reject(error)
                }
            } else {
                Promise.reject(error)
            }
        }
    )
    

    return configAxios
}

export default AxiosServices