import React from "react"
import { NavLink as RouterLink, withRouter } from 'react-router-dom'
import { WEB } from "constants/index"

class CustomMobileNav extends React.Component {

    constructor(props) {
        super(props);
        this.state = { }
    }

    render() {

        return (
            <section className="footerMobile d-flex justify-content-center align-items-center d-block d-sm-none">
                <RouterLink
                    to={WEB.HOMEPAGE}
                    exact={true}
                >
                    <button
                        className="footerMobileLink">
                        <svg width="35" height="31" viewBox="0 0 35 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5001 6.15607L5.0175 17.4229C5.0175 17.4388 5.01385 17.4622 5.00656 17.4941C4.99941 17.5257 4.99561 17.5487 4.99561 17.565V28.9738C4.99561 29.3858 5.13316 29.7428 5.40819 30.0434C5.68314 30.3442 6.00873 30.4955 6.38504 30.4955H14.7211V21.3682H20.2792V30.4958H28.6151C28.9914 30.4958 29.3174 30.3448 29.592 30.0434C29.867 29.7431 30.005 29.3859 30.005 28.9738V17.565C30.005 17.5017 29.9971 17.4539 29.9831 17.4229L17.5001 6.15607Z"/>
                            <path d="M34.7586 14.8557L30.0045 10.5298V0.832098C30.0045 0.610399 29.9395 0.428079 29.8088 0.285386C29.6793 0.14286 29.5127 0.0715963 29.3098 0.0715963H25.1417C24.939 0.0715963 24.7725 0.14286 24.6421 0.285386C24.512 0.428079 24.447 0.610483 24.447 0.832098V5.46696L19.1501 0.617975C18.6875 0.205964 18.1374 0 17.5005 0C16.8637 0 16.3137 0.205964 15.8506 0.617975L0.241234 14.8557C0.0965343 14.9823 0.0172269 15.1526 0.0024756 15.3666C-0.0121997 15.5803 0.0383654 15.7671 0.154323 15.9254L1.50027 17.6844C1.61623 17.8269 1.768 17.914 1.95619 17.9459C2.12994 17.9619 2.30368 17.9063 2.47743 17.7796L17.5001 4.06459L32.5228 17.7795C32.6389 17.8901 32.7906 17.9453 32.9788 17.9453H33.044C33.2319 17.914 33.3834 17.8262 33.4999 17.684L34.846 15.9253C34.9618 15.7667 35.0125 15.5803 34.9974 15.3662C34.9826 15.1528 34.903 14.9825 34.7586 14.8557Z"/>
                        </svg>
                        <p>HOME</p>
                    </button>
                </RouterLink>
                <RouterLink
                    to={WEB.SAHAM_TODAY}
                    exact={true}
                >
                    <button
                        className="footerMobileLink">
                        <svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.0662 13.6161L13.2303 8.5929L1.59925 20.4135L0 18.8411L13.2471 5.37757L18.1301 10.449L26.5301 2.40561L24.1234 0H30.4071V6.2815L28.1159 3.9914L18.0662 13.6161ZM5.56486 30H13.135V19.3458H5.56486V30ZM16.0789 30H23.649V15.9813H16.0789V30ZM26.5929 8.97196V30H34.163V8.97196H26.5929Z"/>
                        </svg>
                        <p>SAHAM TODAY</p>
                    </button>
                </RouterLink>
                <RouterLink
                    to={WEB.ILMU_SAHAM}
                >
                    <button
                        className="footerMobileLink">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 4.32125C14.4825 4.32125 14.0625 3.90125 14.0625 3.38375V0.9375C14.0625 0.42 14.4825 0 15 0C15.5175 0 15.9375 0.42 15.9375 0.9375V3.38375C15.9375 3.90125 15.5175 4.32125 15 4.32125Z"/>
                            <path d="M23.2135 7.72373C22.9736 7.72373 22.7336 7.63248 22.5511 7.44873C22.1848 7.08248 22.1848 6.48873 22.5511 6.12248L24.281 4.39249C24.6473 4.02624 25.241 4.02624 25.6073 4.39249C25.9735 4.75873 25.9735 5.35248 25.6073 5.71873L23.8773 7.44873C23.6935 7.63123 23.4535 7.72373 23.2135 7.72373Z"/>
                            <path d="M29.0625 15.9375H26.6162C26.0987 15.9375 25.6787 15.5175 25.6787 15C25.6787 14.4825 26.0987 14.0625 26.6162 14.0625H29.0625C29.58 14.0625 30 14.4825 30 15C30 15.5175 29.58 15.9375 29.0625 15.9375Z"/>
                            <path d="M24.9435 25.8812C24.7035 25.8812 24.4635 25.7899 24.281 25.6062L22.5511 23.8762C22.1848 23.51 22.1848 22.9162 22.5511 22.55C22.9173 22.1837 23.511 22.1837 23.8773 22.55L25.6073 24.28C25.9735 24.6462 25.9735 25.24 25.6073 25.6062C25.4235 25.7899 25.1835 25.8812 24.9435 25.8812Z"/>
                            <path d="M5.05633 25.8812C4.81633 25.8812 4.57633 25.7899 4.39383 25.6062C4.02758 25.24 4.02758 24.6462 4.39383 24.28L6.12382 22.55C6.49007 22.1837 7.08382 22.1837 7.45007 22.55C7.81632 22.9162 7.81632 23.51 7.45007 23.8762L5.72008 25.6062C5.53633 25.7899 5.29633 25.8812 5.05633 25.8812Z"/>
                            <path d="M3.38375 15.9375H0.9375C0.42 15.9375 0 15.5175 0 15C0 14.4825 0.42 14.0625 0.9375 14.0625H3.38375C3.90125 14.0625 4.32125 14.4825 4.32125 15C4.32125 15.5175 3.90125 15.9375 3.38375 15.9375Z"/>
                            <path d="M6.78632 7.72373C6.54632 7.72373 6.30632 7.63248 6.12382 7.44873L4.39383 5.71873C4.02758 5.35248 4.02758 4.75873 4.39383 4.39249C4.76008 4.02624 5.35383 4.02624 5.72008 4.39249L7.45007 6.12248C7.81632 6.48873 7.81632 7.08248 7.45007 7.44873C7.26507 7.63123 7.02632 7.72373 6.78632 7.72373Z"/>
                            <path d="M18.75 26.25V27.8125C18.75 29.0125 17.7625 30 16.5625 30H13.4375C12.3875 30 11.25 29.2 11.25 27.45V26.25H18.75Z"/>
                            <path d="M20.5126 8.19999C18.4626 6.53749 15.7626 5.88749 13.1251 6.44999C9.81264 7.13749 7.12514 9.8375 6.43764 13.15C5.73764 16.55 7.01264 19.925 9.73764 21.9875C10.4751 22.5375 10.9876 23.3875 11.1626 24.375V24.3875C11.1876 24.375 11.2251 24.375 11.2501 24.375H18.7501C18.7751 24.375 18.7876 24.375 18.8126 24.3875V24.375C18.9876 23.425 19.5501 22.55 20.4126 21.875C22.5251 20.2 23.7501 17.7 23.7501 15C23.7501 12.35 22.5751 9.87499 20.5126 8.19999ZM19.6876 15.625C19.1751 15.625 18.7501 15.2 18.7501 14.6875C18.7501 12.7875 17.2126 11.25 15.3126 11.25C14.8001 11.25 14.3751 10.825 14.3751 10.3125C14.3751 9.79999 14.8001 9.37499 15.3126 9.37499C18.2376 9.37499 20.6251 11.7625 20.6251 14.6875C20.6251 15.2 20.2001 15.625 19.6876 15.625Z"/>
                            <path d="M11.1626 24.375H11.2501C11.2251 24.375 11.1876 24.375 11.1626 24.3875V24.375Z"/>
                            <path d="M18.8125 24.375V24.3875C18.7875 24.375 18.775 24.375 18.75 24.375H18.8125Z"/>
                        </svg>
                        <p>ILMU SAHAM</p>
                    </button>
                </RouterLink>
                <RouterLink
                    to={WEB.VVIP_CLASS}
                >
                    <button
                        className="footerMobileLink">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.9429 11.3361C29.8342 11.0395 29.5829 10.8167 29.2754 10.7442L20.5802 8.72442L15.8125 0.436424C15.475 -0.145475 14.5237 -0.145475 14.1862 0.436424L9.41984 8.72442L0.72458 10.7442C0.417071 10.8167 0.165813 11.0395 0.0570603 11.3361C-0.0516929 11.6339 -0.00294146 11.9668 0.185814 12.2208L6.02474 20.0333L4.14093 28.8656C4.06593 29.2148 4.19718 29.5777 4.47844 29.7992C4.76095 30.0207 5.14221 30.0632 5.46472 29.9055L15 25.2929L24.534 29.9055C24.6628 29.9694 24.8028 29.9994 24.9415 29.9994C25.1478 29.9994 25.3516 29.9306 25.5203 29.7992C25.8028 29.5777 25.9328 29.2148 25.8591 28.8656L23.9753 20.0333L29.8142 12.2208C30.0029 11.9668 30.0517 11.6339 29.9429 11.3361Z"/>
                            <path d="M10.9044 20.3162C10.5443 20.3162 10.2225 20.0758 10.0924 19.707L8.05942 13.9833C7.88516 13.4924 8.10705 12.9404 8.55663 12.7483C9.00273 12.5613 9.5104 12.8005 9.68465 13.2927L10.9044 16.7307L12.1254 13.294C12.2997 12.8017 12.8108 12.5626 13.2534 12.7496C13.703 12.9404 13.9249 13.4937 13.7506 13.9846L11.7176 19.7082C11.5864 20.0758 11.2646 20.3162 10.9044 20.3162Z" fill="#333333"/>
                            <path d="M15.2609 20.3162C14.78 20.3162 14.3896 19.8888 14.3896 19.3622V13.6386C14.3896 13.1121 14.78 12.6847 15.2609 12.6847C15.7419 12.6847 16.1322 13.1121 16.1322 13.6386V19.3622C16.1322 19.8888 15.7419 20.3162 15.2609 20.3162Z" fill="#333333"/>
                            <path d="M17.8747 20.3162C17.3938 20.3162 17.0034 19.8888 17.0034 19.3622V13.6386C17.0034 13.1121 17.3938 12.6847 17.8747 12.6847C18.3557 12.6847 18.746 13.1121 18.746 13.6386V19.3622C18.746 19.8888 18.3557 20.3162 17.8747 20.3162Z" fill="#333333"/>
                            <path d="M19.3268 17.7723H17.8747C17.3938 17.7723 17.0034 17.345 17.0034 16.8184V13.6386C17.0034 13.1121 17.3938 12.6847 17.8747 12.6847H19.3268C20.6082 12.6847 21.6503 13.8256 21.6503 15.2285C21.6503 16.6314 20.6082 17.7723 19.3268 17.7723ZM18.746 15.8645H19.3268C19.6463 15.8645 19.9077 15.5783 19.9077 15.2285C19.9077 14.8787 19.6463 14.5926 19.3268 14.5926H18.746V15.8645Z" fill="#333333"/>
                        </svg>
                        <p>VVIP CLASS</p>
                    </button>
                </RouterLink>
                <RouterLink
                    to={WEB.VVIP_SPECIAL}
                >
                    <button
                        className="footerMobileLink">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.9429 11.3361C29.8342 11.0395 29.5829 10.8167 29.2754 10.7442L20.5802 8.72442L15.8125 0.436424C15.475 -0.145475 14.5237 -0.145475 14.1862 0.436424L9.41984 8.72442L0.72458 10.7442C0.417071 10.8167 0.165813 11.0395 0.0570603 11.3361C-0.0516929 11.6339 -0.00294146 11.9668 0.185814 12.2208L6.02474 20.0333L4.14093 28.8656C4.06593 29.2148 4.19718 29.5777 4.47844 29.7992C4.76095 30.0207 5.14221 30.0632 5.46472 29.9055L15 25.2929L24.534 29.9055C24.6628 29.9694 24.8028 29.9994 24.9415 29.9994C25.1478 29.9994 25.3516 29.9306 25.5203 29.7992C25.8028 29.5777 25.9328 29.2148 25.8591 28.8656L23.9753 20.0333L29.8142 12.2208C30.0029 11.9668 30.0517 11.6339 29.9429 11.3361Z"/>
                            <path d="M10.9044 20.3162C10.5443 20.3162 10.2225 20.0758 10.0924 19.707L8.05942 13.9833C7.88516 13.4924 8.10705 12.9404 8.55663 12.7483C9.00273 12.5613 9.5104 12.8005 9.68465 13.2927L10.9044 16.7307L12.1254 13.294C12.2997 12.8017 12.8108 12.5626 13.2534 12.7496C13.703 12.9404 13.9249 13.4937 13.7506 13.9846L11.7176 19.7082C11.5864 20.0758 11.2646 20.3162 10.9044 20.3162Z" fill="#333333"/>
                            <path d="M15.2609 20.3162C14.78 20.3162 14.3896 19.8888 14.3896 19.3622V13.6386C14.3896 13.1121 14.78 12.6847 15.2609 12.6847C15.7419 12.6847 16.1322 13.1121 16.1322 13.6386V19.3622C16.1322 19.8888 15.7419 20.3162 15.2609 20.3162Z" fill="#333333"/>
                            <path d="M17.8747 20.3162C17.3938 20.3162 17.0034 19.8888 17.0034 19.3622V13.6386C17.0034 13.1121 17.3938 12.6847 17.8747 12.6847C18.3557 12.6847 18.746 13.1121 18.746 13.6386V19.3622C18.746 19.8888 18.3557 20.3162 17.8747 20.3162Z" fill="#333333"/>
                            <path d="M19.3268 17.7723H17.8747C17.3938 17.7723 17.0034 17.345 17.0034 16.8184V13.6386C17.0034 13.1121 17.3938 12.6847 17.8747 12.6847H19.3268C20.6082 12.6847 21.6503 13.8256 21.6503 15.2285C21.6503 16.6314 20.6082 17.7723 19.3268 17.7723ZM18.746 15.8645H19.3268C19.6463 15.8645 19.9077 15.5783 19.9077 15.2285C19.9077 14.8787 19.6463 14.5926 19.3268 14.5926H18.746V15.8645Z" fill="#333333"/>
                        </svg>
                        <p>VVIP SPECIAL</p>
                    </button>
                </RouterLink>
            </section>
        )
    }
}

export default withRouter(CustomMobileNav)