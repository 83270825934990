import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom"
import MainLayout from 'pages/layout/MainLayout'
import { MainProvider } from './constants'

class MainRouter extends React.Component {
    render() {
        return (
            <Router>
                <React.Suspense fallback={<span>Loading items...</span>}>
                    <Switch>
                        <MainProvider >
                            <Route path="/" component={MainLayout} />
                        </MainProvider>
                    </Switch>
                </React.Suspense>
            </Router>
        )
    }
}

export default MainRouter