import React from 'react'
import { MainContext } from 'constants/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from "react-router-dom"

class BreadcrumbMobile extends React.Component {
    static contextType = MainContext

    constructor(props, context) {
        super(props);
        this.state = {  }
        this.context = context
    }

    click_backArrow = () => {
        const { props } = this

        return props.history.goBack()
    }

    render() {
        const { context, props } = this

        return (
            <section className="breadcrumbMobile">
                {
                    props.location.pathname !== '/' &&
                        <FontAwesomeIcon icon={faChevronLeft} onClick={() => this.click_backArrow()} />
                }
                <p className="title">{context.title}</p>
            </section>
        )
    }
}

export default withRouter(BreadcrumbMobile)