import React from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'


class CardSkeleton extends React.Component {

    generate_cardSkeleton = () => {
        const { props } = this
        const card = []

        for (let index = 0; index < props.count; index++) {
            card[index] = (
                <Col 
                    key={index}
                    sm={props.sm} 
                    md={props.md} 
                    className="cardSkeleton pt-3">
                    <Card>
                        <SkeletonTheme 
                            color={props.color} 
                            highlightColor={props.highlight}>
                                <Skeleton 
                                    height={props.thumbnailHeight}/>
                                <Card.Body>
                                    <Skeleton 
                                        count={props.listCount}/>
                                </Card.Body>
                        </SkeletonTheme>
                    </Card>
                </Col>
            )
        }

        return card

    }

    render() {
        return (
            <>
                {
                    this.generate_cardSkeleton()
                }
            </>
        )
    }
}

CardSkeleton.defaultProps = {
    count: 1,
    color: '#979797',
    highlight: '#F4F4F4',
    thumbnailHeight: 100,
    listCount: 1,
}

CardSkeleton.propTypes = {
    count: PropTypes.number,
    color: PropTypes.string,
    highlight: PropTypes.string,
    thumbnailHeight: PropTypes.number,
    listCount: PropTypes.number,
}

export default CardSkeleton