import React from 'react'
import { CustomMobileNav } from 'components/index'
import { WEB } from 'constants/index'
import { withRouter } from "react-router-dom"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { }
    }
    render() {
        const { props } = this

        return (
            <>
                {
                    props.location.pathname !== WEB.AUTH_LOGIN &&
                    props.location.pathname !== WEB.AUTH_REGISTER &&
                    props.location.pathname !== WEB.AUTH_FORGOT_PASSWORD &&
                    props.location.pathname !== WEB.AUTH_CHANGE_PASSWORD &&
                    props.location.pathname !== WEB.PROFILE &&
                    props.location.pathname !== WEB.PROFILE_FORM &&
                    props.location.pathname !== WEB.PROFILE_RIWAYAT_PEMBAYARAN &&
                    props.location.pathname !== WEB.PRIVACY_POLICY &&
                    props.location.pathname !== WEB.TERMS_CONDITIONS &&

                        <CustomMobileNav />
                }

                <footer>
                    <div className="container py-3">
                        <Row>
                            <Col xs={12} className="copyright text-center">
                                &#169; 2021 Ilmusaham. All Rights Reserved
                            </Col>
                        </Row>
                    </div>
                </footer>
            </>
        )
    }
}

export default withRouter(Footer)
