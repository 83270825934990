import React from 'react'
import 'moment/locale/id'
import { WEB } from 'constants/Url'
import { NavLink as RouterLink, withRouter } from 'react-router-dom'
import { Avatar, ImageDefault, LogoIlmusahamWhite } from 'assets/images/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBellSlash, faBars } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container'
import { get_profileDetail } from 'actions/profile/index'
import { get_listNotifications } from 'actions/homepage'
import { MainContext } from 'constants/index'
import { checkExpiresToken, getToken, removeToken } from 'helpers/index'
import { PopoverCustom } from 'components'
import { isEmptyArray } from "formik"

class Header extends React.Component {
    static contextType = MainContext

    constructor(props, context) {
        super(props);
        this.state = { 
            profileData: {
                data: [],
                isLogged: false,
                isLoading: true,
            },
            isPopoverOpen: false,
            targetPopover: null,
            isNotifDisabled: false,
        }
        this.context = context
        this.popperRef = React.createRef()
        this.popperRefMobile = React.createRef()
    }

    async componentDidMount() {
        const { setLogged, setPageShow } = this.context
        const token = await getToken()
        const isExpiredToken = await checkExpiresToken()
        if (token || isExpiredToken) {
            setTimeout(() => {
                this.api_getProfile()
                this.api_getNotification()
            }, 1000)
        } else {
            setLogged(false)
            setPageShow(true)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { props } = this

        if (prevProps !== props) {
            this.click_iconNotif()
        }
    }

    api_getProfile = async () => {
        const { props } = this
        const { setLogged, setProfile, setPageShow } = this.context
        const profile = await get_profileDetail()
        
        if (profile && profile.status === 200) {
            setProfile(profile.data)
            setLogged(true)
            setPageShow(true)

            this.setState({
                profileData : {
                    isLoading: false
                },
            })

        } else {

            this.setState({
                profileData : {
                    isLoading: false
                },
            })

            removeToken()
            setProfile(null)
            setLogged(false)
            setPageShow(true)
            props.history.push({
                pathname: WEB.AUTH_LOGIN
            })
        }
    }

    api_getNotification = async () => {
        const { setNotifications, setIsNotifLoading } = this.context
        const notification = await get_listNotifications()

        if (notification && notification.status === 200) {
            const { results } = notification.data

            setNotifications(results)
            setIsNotifLoading(false)
        }
    }

    click_iconNotif = (e) => {
        const { state } = this

        this.setState({
            isPopoverOpen: e ? !state.isPopoverOpen : false,
            targetPopover: e ? e.target : null
        })
    }

    render() {
        const { context, popperRef, popperRefMobile, props, state } = this
        
        return (
            <>
                <header>
                    <Container>
                        {
                            props.location.pathname !== WEB.AUTH_LOGIN &&
                            props.location.pathname !== WEB.AUTH_REGISTER &&
                            props.location.pathname !== WEB.AUTH_FORGOT_PASSWORD &&
                            props.location.pathname !== WEB.AUTH_CHANGE_PASSWORD &&
                            props.location.pathname !== WEB.PRIVACY_POLICY &&
                            props.location.pathname !== WEB.TERMS_CONDITIONS &&


                            <div className="d-block d-sm-none">
                                <button
                                    className="menuMobile"
                                    onClick={props.onClcikSideMenu}
                                    aria-label="Menu Mobile">
                                    <FontAwesomeIcon icon={faBars} />
                                </button>
                            </div>
                        }
                        <RouterLink
                            className="p-3 logo"
                            to={WEB.HOMEPAGE}>
                            <img
                                src={LogoIlmusahamWhite}
                                alt="Ilmu Saham Logo" />
                        </RouterLink>
                        <div className="logoMobile">
                            <img
                                src={LogoIlmusahamWhite}
                                alt="Ilmu Saham Logo" />
                        </div>
                        <div className="btnActionUser">
                            {
                                context.isLogged == false && context.isPageShow === true ?
                                <>
                                    <RouterLink
                                        to={WEB.AUTH_LOGIN}>
                                        <button
                                            className="btn mx-1 btnSignin" >
                                            Masuk
                                        </button>
                                    </RouterLink>
                                    <RouterLink
                                        to={WEB.AUTH_REGISTER}>
                                        <button
                                            className="btn mx-1 btnRegister" >
                                            Daftar
                                        </button>
                                    </RouterLink>
                                </>
                            :
                                // context.isLogged == true && context.isPageShow === true &&
                                <div className="d-flex profile justify-content-center align-items-center">
                                    <div className="notifBell p-1" ref={popperRef}>
                                        <FontAwesomeIcon
                                            icon={context.isNotifLoading ? faBellSlash : faBell}
                                            size="lg"
                                            onClick={this.click_iconNotif} />

                                        {
                                            context.notifications &&
                                            <>
                                                {
                                                    context.profile &&
                                                    context.profile.notification_count > 0 &&
                                                    <div className="redDot" />
                                                }
                                                <PopoverCustom
                                                    title="Notifikasi"
                                                    isShow={state.isPopoverOpen}
                                                    placement="bottom"
                                                    target={state.targetPopover}
                                                    container={popperRef.current}
                                                    popoverId="popover-contained"
                                                    data={context.notifications}
                                                    onClick={this.click_iconNotif}
                                                />
                                            </>
                                        }
                                    </div>
                                    <div className="p-1">
                                    <RouterLink to={WEB.PROFILE}>
                                        <img
                                            src={context.profile && context.profile.avatar || 'https://gravatar.com/avatar/52691619719441629d5ec52920023b3b?s=200&d=identicon&r=r'}
                                            alt="Profile" />
                                    </RouterLink>
                                    </div>
                                    <RouterLink to={WEB.PROFILE}>
                                        <div className="p-1">
                                            <p>{
                                                context.profile &&
                                                `${context.profile.first_name} ${context.profile.last_name}`
                                            }</p>
                                        </div>
                                    </RouterLink>
                                </div>
                            }
                        </div>
                        {
                            props.location.pathname !== WEB.AUTH_LOGIN &&
                            props.location.pathname !== WEB.AUTH_REGISTER &&
                            props.location.pathname !== WEB.AUTH_FORGOT_PASSWORD &&
                            props.location.pathname !== WEB.AUTH_CHANGE_PASSWORD &&
                            props.location.pathname !== WEB.PRIVACY_POLICY &&
                            props.location.pathname !== WEB.TERMS_CONDITIONS &&


                            <div className="notificationMobile d-block d-sm-none" ref={popperRefMobile}>
                                <FontAwesomeIcon
                                    icon={context.isNotifLoading ? faBellSlash : faBell}
                                    size="lg"
                                    onClick={!state.isNotifDisabled && this.click_iconNotif} />

                                {
                                    context.notifications &&
                                    <>
                                        {
                                            !isEmptyArray(context.notifications) &&
                                            <div className="redDot" />
                                        }
                                        <PopoverCustom
                                            title="Notifikasi"
                                            isShow={state.isPopoverOpen}
                                            placement="bottom"
                                            target={state.targetPopover}
                                            container={popperRefMobile.current}
                                            popoverId="popover-contained-mobile"
                                            data={context.notifications}
                                            onClick={this.click_iconNotif}
                                        />
                                    </>
                                }
                            </div>
                        }
                    </Container>
                </header>

                {
                    props.location.pathname !== WEB.AUTH_LOGIN &&
                    props.location.pathname !== WEB.AUTH_REGISTER &&
                    props.location.pathname !== WEB.AUTH_FORGOT_PASSWORD &&
                    props.location.pathname !== WEB.AUTH_CHANGE_PASSWORD &&
                    props.location.pathname !== WEB.PRIVACY_POLICY &&
                    props.location.pathname !== WEB.TERMS_CONDITIONS &&

                    <section className="menuNav">
                        <div className="d-flex container">
                            <RouterLink 
                                className="flex-fill p-3" 
                                activeClassName="selected"
                                to={WEB.HOMEPAGE} exact>
                                    HOME
                            </RouterLink>
                            <RouterLink 
                                className="flex-fill p-3" 
                                activeClassName="selected"
                                to={WEB.ILMU_SAHAM}>
                                    ILMU SAHAM
                            </RouterLink>
                            <RouterLink 
                                className="flex-fill p-3" 
                                activeClassName="selected"
                                to={WEB.SAHAM_TODAY} exact>
                                    SAHAM TODAY
                            </RouterLink>
                            <RouterLink 
                                className="flex-fill p-3" 
                                activeClassName="selected"
                                to={WEB.VVIP_CLASS}>
                                    VVIP CLASS
                            </RouterLink>
                            <RouterLink 
                                className="flex-fill p-3" 
                                activeClassName="selected"
                                to={WEB.VVIP_SPECIAL}>
                                    VVIP SPECIAL
                            </RouterLink>
                        </div>
                    </section>
                }
            </>
        )
    }
}

export default withRouter(Header)