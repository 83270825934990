import React from 'react'
import Alert from 'react-bootstrap/Alert'
import PropTypes from 'prop-types'

class AlertCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isShow: true
        }
    }

    componentDidMount() {
        const { props } = this

        if (props.alertShow) {
            this.setState({
                isShow: props.alertShow
            })
        }
    }

    click_closeAlert = () => {
        this.setState({
            isShow: false
        })
    }

    render() {
        const { props, state } = this

        return (
            <Alert 
                show={props.alertShow && state.isShow}
                variant={props.alertVariant ?? 'danger'} 
                onClose={() => this.click_closeAlert()} 
                dismissible>

                <Alert.Heading>
                    {props.alertHeader ?? 'Oh snap! You got an error!'}
                </Alert.Heading>

                <p>
                    { props.alertBody }
                </p>

            </Alert>
        )
    }
}

AlertCustom.propTypes = {
    alertHeader: PropTypes.string,
    alertBody: PropTypes.string,
    alertShow: PropTypes.bool,
    alertVariant: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
    ]),
}

export default AlertCustom;