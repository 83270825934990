import React from 'react'
import { MainContext } from 'constants/index'

class Breadcrumb extends React.Component {
    static contextType = MainContext

    constructor(props, context) {
        super(props);
        this.state = {  }
        this.context = context
    }

    render() {
        const { context } = this
        return (
            <ul className="breadcrumb d-none d-md-block d-lg-block d-xl-block d-lg-none">
                {
                    context.breadcrumb.map((item, key) => (
                        <li key={key}>
                            {item.href !== null && <a href={item.href}>{item.content}</a>}
                            {item.href === null && <b>{item.content}</b>}
                        </li>
                    ))
                }
            </ul>
        )
    }
}

export default Breadcrumb