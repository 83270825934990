import { AxiosServices } from 'helpers/index'
import { API } from 'constants/index'

const post_loginUser = (formData) => AxiosServices(false).post(API.LOGIN, formData)
const post_loginUserSocialMedia = (formData) => AxiosServices(false).post(API.LOGIN_SOCIAL_MEDIA, formData)
const post_registerUser = (formData) => AxiosServices(false).post(API.REGISTER, formData)
const post_forgotPasswordUser = (formData) => AxiosServices(false).post(API.FORGOT_PASSWORD, formData)
const post_logoutUser = (formData) => AxiosServices(false).post(API.LOGOUT, formData)
const post_fcmDeviceUser = (params) => AxiosServices(true).post(API.FCM_DEVICES, params)

export {
    post_loginUser,
    post_loginUserSocialMedia,
    post_registerUser,
    post_forgotPasswordUser,
    post_logoutUser,
    post_fcmDeviceUser,
}