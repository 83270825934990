import React from 'react'
import moment from 'moment'
import 'moment/locale/id'
import PropTypes from 'prop-types'
import { WEB } from 'constants/Url'
import Popover from "react-bootstrap/Popover"
import { NotifDefault } from "assets/images"
import Overlay from "react-bootstrap/Overlay"
import { isEmptyArray } from "formik"
import { get_notificationsMarkAsRead } from 'actions/homepage'

class PopoverCustom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShow: false
        }
        this.popoverRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { props, state } = this
        if (prevProps.isShow !== props.isShow) {
            this.setState({
                isShow: !state.isShow
            })
        }
    }

    checkTypeNotif = (notifValue) => {
        let detailNotificationUrl = window.location.origin
        
        switch (notifValue.data.type) {
            case 'ilmu-saham':
                detailNotificationUrl += WEB.ILMU_SAHAM_DETAIL.replace(':slug', notifValue.data.detail_id)
                break;
            case 'payment':
                detailNotificationUrl += WEB.PAKETPAGE_PAYMENT.replace(':slug', notifValue.data.detail_id)
                break;
            case 'vvip-special':
                detailNotificationUrl += WEB.VVIP_SPECIAL_VIDEO_DETAIL.replace(':slug', notifValue.data.detail_id)
                break;
            case 'vvip-class':
                detailNotificationUrl += WEB.VVIP_CLASS_VIDEO_DETAIL.replace(':slug', notifValue.data.detail_id)
                break;
            case 'saham-today':
                detailNotificationUrl += WEB.SAHAM_TODAY
                break;
            default:
                detailNotificationUrl += WEB.HOMEPAGE
        }

        return detailNotificationUrl
    }

    click_itemList = async (itemId) => {
        const { props } = this

        const clickItemList = await get_notificationsMarkAsRead(itemId)
        if (clickItemList) {
            return props.onClick
        }
    }

    click_outsidePopover = (e) => {
        const className = e.target.getAttribute('class')
        const listClass = [
            "btnActionUser",
            "groupByDate",
            "innerBody",
            "detailNotif",
            "popover-header",
            "popover-body",
            "popover-contained"
        ]

        if (!listClass.includes(className)) {
            this.setState({
                isShow: false
            })
        }
    }

    generateItemList = (notifValue) => {
        const pathNotif = this.checkTypeNotif(notifValue)

        return (
            <a
                href={pathNotif}
                key={notifValue.id}
                onClick={() => this.click_itemList(notifValue.id) }>
                <div className="detailNotif">
                    {notifValue.data.thumbnail && <img src={notifValue.data.thumbnail} alt="Notification" />}
                    <div className="detailNotifText">
                        <p className="title">{notifValue.verb}</p>
                        <p className="date">{moment(notifValue.timestamp).startOf('hour').fromNow()}</p>
                    </div>
                </div>
            </a>
        )
    }

    render() {
        const { props, state } = this

        return (
            <Overlay
                show={state.isShow}
                placement={props.placement}
                target={props.target}
                container={props.container}
                containerPadding={20}
                transition={true}
                rootClose={true}
                onHide={(e) => this.click_outsidePopover(e)}
            >
                <Popover id={props.popoverId}>
                    <Popover.Title as="h3">{props.title}</Popover.Title>
                    <Popover.Content ref={this.popoverRef}>
                        {
                            props.data &&
                            !isEmptyArray(props.data) ?
                                props.data.map((group, key) => (
                                        <div key={key}>
                                            <div className="groupByDate">
                                                {moment(group.date).format('MMMM Do YYYY')}
                                            </div>
                                            <div className="innerBody">
                                                {group.extra.map((notif) => (
                                                    this.generateItemList(notif)
                                                ))}
                                            </div>
                                        </div>
                                    )
                                )
                            :
                                <p className="text-center">Data kosong</p>
                        }
                    </Popover.Content>
                </Popover>
            </Overlay>
        )
    }
}

PopoverCustom.defaultProps = {
    title: "Notifikasi",
    isShow: false,
    placement: "bottom",
    target: null,
    container: null,
    popoverId: null,
    data: [],
}

PopoverCustom.propTypes = {
    title: PropTypes.string,
    isShow: PropTypes.bool,
    placement: PropTypes.string,
    target: PropTypes.object,
    container: PropTypes.any,
    popoverId: PropTypes.string,
    data: PropTypes.array,
    onClick: PropTypes.func,
}

export default PopoverCustom