import React from 'react'
import 'assets/scss/main.scss'
import { WEB } from 'constants/index'
import { NavLink as RouterLink, Route, Switch } from "react-router-dom"
import { MainContext } from 'constants/index'
import { Footer, Header } from 'pages/layout/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faPowerOff, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { removeToken, onMessageListener } from 'helpers/index'
import { DimmerCustom, BreadcrumbMobile, PrivateRoute, PublicRoute } from 'components/index'
import { Online } from 'react-detect-offline'
import NoInternet from 'pages/layout/NoInternet'
import GoogleLogout from 'react-google-login'
import Button from 'react-bootstrap/Button'

const IlmuSaham             = React.lazy(() => import('../ilmu-saham'))
const IlmuSahamDetail       = React.lazy(() => import('../ilmu-saham/Detail'))
const SahamToday            = React.lazy(() => import('../saham-today'))
const Homepage              = React.lazy(() => import('../homepage'))
const PaketPage             = React.lazy(() => import('../paket'))
const PaketPagePayment      = React.lazy(() => import('../paket/Payment'))
const VvipClassPage         = React.lazy(() => import('../vvip-class'))
const VvipClassVideo        = React.lazy(() => import('../vvip-class/ListVideo'))
const VvipClassVideoDetail  = React.lazy(() => import('../vvip-class/Detail'))
const VvipSpecialVideoDetail= React.lazy(() => import('../vvip-special/Detail'))
const VvipSpecialVideo      = React.lazy(() => import('../vvip-special'))
const ProfileLayout         = React.lazy(() => import('../profile/Layout'))
const Verification          = React.lazy(() => import('../verification'))
const PrivacyPolicy         = React.lazy(() => import('../privacy-policy'))
const TermsConditions       = React.lazy(() => import('../terms-conditions'))

const LoginPage             = React.lazy(() => import('../auth/Login'))
const RegisterPage          = React.lazy(() => import('../auth/Register'))
const ForgotPasswordPage    = React.lazy(() => import('../auth/ForgotPassword'))
const NotFoundPage          = React.lazy(() => import('../layout/NotFound'))

class MainLayout extends React.Component {
    static contextType = MainContext

    constructor(props, context) {
        super(props);
        this.state = { 
            isSideMenuOpen: false,
            isPopoverOpen: false,
            isOnline: true,
            firebaseNotif: {
                isTokenFirebaseFound: false,
                isShow: false,
                data: null,
            },
        }
        this.context = context
    }

    componentDidMount() {
        onMessageListener().then(
            payload => {
                this.set_firebaseNotification({
                    isShow: true,
                    data: {
                        title: payload.notification.title,
                        body: payload.notification.body
                    }
                })
            }
        ).catch(err => console.log('failed: ', err))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { context, props, state } = this
        const { setPageShow } = this.context

        if (prevProps.location.pathname !== props.location.pathname) {
            if (state.isPopoverOpen) {
                this.click_popover()
            }
            if (context.isLogged) {
                setPageShow(true)
            }
        }
    }

    click_menuButton = () => {
        const { state } = this
        
        this.setState({
            isSideMenuOpen: !state.isSideMenuOpen
        }, () => {
            document.body.style.overflow = !state.isSideMenuOpen ? "hidden" : "scroll"
        })
    }

    click_buttonLogout = () => {
        const { props } = this
        const { setAuthProvider, setProfile, setLogged } = this.context
        const authProvider = localStorage.getItem("authProvider")

        if (authProvider === 'facebook' && window.FB) {
            window.FB.logout(() => {
                return null
            })
        } else if (authProvider === 'google-oauth2') {
            const auth2 = window.gapi.auth2.getAuthInstance()
            if (auth2 != null) {
                auth2.signOut()
                    .then(auth2.disconnect())
            }
        }

        removeToken()
        setProfile([])
        setAuthProvider(null)
        setLogged(false)
        props.history.replace({
            pathname: WEB.AUTH_LOGIN,
        })
    }

    click_popover = () => {
        const { state } = this

        this.setState({
            isPopoverOpen: !state.isPopoverOpen
        }, () => {
            document.body.style.overflow = !state.isPopoverOpen ? "hidden" : "scroll"
        })
    }

    set_firebaseTokenFound = (nextValue) => {
        this.setState({
            firebaseNotif: {
                isTokenFirebaseFound: nextValue
            }
        })
    }

    set_firebaseNotification = (notification) => {
        if (notification) {
            this.setState({
                firebaseNotif: {
                    isShow: notification.isShow,
                    data: notification.data,
                }
            })
        }
    }

    set_offline = (nextStatus) => {
        this.setState({
            isOnline: nextStatus
        })
    }

    render() {
        const { props, state } = this

        return (
            <>
                <Header
                    onClcikSideMenu={this.click_menuButton}
                    onClickLogout={this.click_buttonLogout}
                    onClickPopover={this.click_popover}
                    isPopoverOpen={state.isPopoverOpen} />

                {
                    props.location.pathname !== WEB.AUTH_LOGIN &&
                    props.location.pathname !== WEB.AUTH_REGISTER &&
                    props.location.pathname !== WEB.AUTH_FORGOT_PASSWORD &&
                    props.location.pathname !== WEB.AUTH_CHANGE_PASSWORD &&
                    props.location.pathname !== WEB.PRIVACY_POLICY &&
                    props.location.pathname !== WEB.TERMS_CONDITIONS &&

                    <>
                        <nav
                            className="sideMenu"
                            style={{ left: state.isSideMenuOpen && '0px', opacity: state.isSideMenuOpen && '1' }}
                            onClick={() => this.click_menuButton()}>
                            <div
                                className="sideMenuNav"
                                style={{ left: state.isSideMenuOpen && '0px' }}>
                                <ul>
                                    <li>
                                        <FontAwesomeIcon icon={faUserCircle} />
                                        <RouterLink to={WEB.PROFILE}>
                                            Profil
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faShoppingBag} />
                                        <RouterLink to={WEB.PROFILE_RIWAYAT_PEMBAYARAN}>
                                            Pembayaran
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <GoogleLogout
                                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                            render={() => (
                                                <Button
                                                    className="btnLogout"
                                                    size="lg"
                                                    onClick={() => this.click_buttonLogout()} >
                                                    <FontAwesomeIcon icon={faPowerOff} /> Log Out
                                                </Button>
                                            )}
                                            buttonText="Logout"
                                        >
                                        </GoogleLogout>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                        <BreadcrumbMobile />
                    </>
                }

                <main className="mainContent">
                    <React.Suspense fallback={<DimmerCustom />}>
                        <Switch>
                            <PrivateRoute path={WEB.HOMEPAGE} component={Homepage} exact/>
                            <PrivateRoute path={WEB.PAKETPAGE} component={PaketPage} exact />
                            <PrivateRoute path={WEB.PAKETPAGE_PAYMENT} component={PaketPagePayment} exact/>
                            <PrivateRoute path={WEB.ILMU_SAHAM} component={IlmuSaham} exact />
                            <PrivateRoute path={WEB.ILMU_SAHAM_DETAIL} component={IlmuSahamDetail} exact />
                            <PrivateRoute path={WEB.SAHAM_TODAY} component={SahamToday} exact />
                            <PrivateRoute path={WEB.VVIP_CLASS} component={VvipClassPage} exact />
                            <PrivateRoute path={WEB.VVIP_SPECIAL} component={VvipSpecialVideo} exact />
                            <PrivateRoute path={WEB.VVIP_SPECIAL_VIDEO_DETAIL} component={VvipSpecialVideoDetail} exact />
                            <PrivateRoute path={WEB.VVIP_CLASS_VIDEO} component={VvipClassVideo} exact />
                            <PrivateRoute path={WEB.VVIP_CLASS_VIDEO_DETAIL} component={VvipClassVideoDetail} exact />
                            <PrivateRoute path={WEB.PROFILE} component={ProfileLayout} />
                            <PrivateRoute path={WEB.VERIFICATION} component={Verification} />

                            <PublicRoute path={WEB.AUTH_LOGIN} component={LoginPage} exact />
                            <PublicRoute path={WEB.AUTH_REGISTER} component={RegisterPage} exact />
                            <PublicRoute path={WEB.AUTH_FORGOT_PASSWORD} component={ForgotPasswordPage} exact />
                            <PublicRoute path={WEB.PRIVACY_POLICY} component={PrivacyPolicy} />
                            <PublicRoute path={WEB.TERMS_CONDITIONS} component={TermsConditions} />

                            <Route component={NotFoundPage} />
                        </Switch>
                    </React.Suspense>
                </main>

                <Footer />
            </>
        )
    }
}

export default MainLayout