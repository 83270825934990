import { AxiosServices } from 'helpers/index'
import { API } from 'constants/index'

const get_packageList = () => AxiosServices().get(API.PACKAGES)
const get_packageDetail = (packageId) => AxiosServices().get(`${API.PACKAGES}/${packageId}`)
const get_paymentDetail = (paymentId) => AxiosServices().get(`${API.PAYMENTS}/${paymentId}`)
const post_paymentPackage = (params) => AxiosServices().post(API.PAYMENTS, params)
const get_payments = () => AxiosServices().get(`${API.PAYMENTS}`)

export {
    get_packageList,
    get_packageDetail,
    get_paymentDetail,
    post_paymentPackage,
    get_payments
}