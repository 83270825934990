import { AxiosServices } from 'helpers/index'
import { API } from 'constants/index'

const get_profileDetail = () => AxiosServices().get(API.PROFILE)
const post_profileDetail = (formData) => AxiosServices().put(API.PROFILE, formData)
const post_password = (formData) => AxiosServices().put(API.PROFILE_CHANGE_PASSWORD, formData)
const get_verificationData = () => AxiosServices().get(API.VERIFICATION)
const put_verificationData = (data) => AxiosServices().put(API.VERIFICATION, data)
const delete_profile = () => AxiosServices().delete(API.PROFILE_DELETE_ACCOUNT)

export {
    get_profileDetail,
    post_profileDetail,
    post_password,
    get_verificationData,
    put_verificationData,
    delete_profile
}