import * as Yup from 'yup'

const initFormLogin = {
    username: '',
    password: '',
    grant_type: 'password',
    scope: 'read write',
    client_id: '',
    client_secret: '',
}

const initFormRefreshToken = {
    grant_type: 'refresh_token',
    refresh_token: '',
    client_id: '',
    client_secret: '',
}

const initFormRegister = {
    first_name: '',
    last_name: '',
    telephone: '',
    email: '',
    password: '',
    passwordConfirm: '',
}

const initFormForgotPassword = {
    email: '',
    grant_type: 'password',
    scope: 'read write',
    client_id: '',
    client_secret: '',
}

const initFcmDevice = {
    name: '',
    registration_id: '',
    device_id: '',
    active: false,
    type: "web",
}

const initLoginSocialMedia = {
    grant_type: "convert_token",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    backend: '',
    token: ''
}

const vald_formSignin = Yup.object().shape({
    username: Yup.string()
        // .email('Invalid email address')
        .required('This field is required'),
    password: Yup.string()
        .required('This field is required') 
        .matches(/[\W\S_]/, 'Only Numbers and letters.')
})

const vald_formSignUp = Yup.object().shape({
    first_name: Yup.string()
        .required('This field is required'),
    last_name: Yup.string()
        .required('This field is required'),
    telephone: Yup.number()
        .typeError('Value must be a number')
        .required('This field is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('This field is required'),
    password: Yup.string()
        .required('This field is required') 
        .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, 'Password must contain 1 or more uppercase characters and special characters.'),
    passwordConfirm: Yup.string()
        .required('This field is required') 
        .oneOf([Yup.ref("password"), null], "Confirm passwords must match")
})

const vald_formForgotPassword = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('This field is required')
})

export {
    initFcmDevice,
    initFormLogin,
    initFormRegister,
    initFormRefreshToken,
    initFormForgotPassword,
    initLoginSocialMedia,
    vald_formSignin,
    vald_formSignUp,
    vald_formForgotPassword,
}