const serializeForm = (initial, formData, type='json') => {
    const fixFormData = type === 'form-data' ? new FormData() : {}

    for (let key in initial) {
        if (initial.hasOwnProperty(key) && formData.hasOwnProperty(key)) {
            if (formData[key] !== null && formData[key] !== '' && formData[key] !== undefined) {
                if (type === 'json') {
                    fixFormData[key] = formData[key]
                } else {
                    fixFormData.append(key, formData[key])
                }
            }
        }
    }

    return fixFormData
}

export default serializeForm